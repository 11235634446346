/* todo: QF-998 - fix this if we decide to keep this library */
@import "react-datepicker/dist/react-datepicker.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@layer components {
  .hide-scroll:-webkit-scrollbar {
    display: none;
  }

  .hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@layer utilities {
  .stable-scroll {
    scrollbar-gutter: stable;
  }
}
